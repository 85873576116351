
  import AeqDocumentPage from '~/components/AeqDocumentPage'
  import AeqDocumentList from '~/components/AeqDocumentList'

  export default {
    name: `AeqFileField`,
    components: { AeqDocumentList, AeqDocumentPage },
    props: {
      errorMessages: {
        type: [String, Array],
        default: () => []
      },
      hint: {
        type: String,
        default: ``
      },
      listLight: {
        type: Boolean,
        default: false
      },
      multiple: {
        type: Boolean,
        default: false
      },
      small: {
        type: Boolean,
        default: false
      },
      template: {
        type: String,
        default: ``
      },
      token: {
        type: String,
        default: null
      },
      value: {
        type: Object,
        default: null
      },
      showUploadContainer: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        filteredProps: {},
        files: [],
        requirements: this.$attrs?.requirements,
        innerValue: this.initValue(this.value),
        dragging: false,
        refreshCounter: 0
      }
    },
    computed: {
      sizeLimit() {
        const data = this.requirements?.size || this.$config.public.default_file_limit
        return {
          data,
          msg: !!data ? `(${this.$t(`components.files_max_size`)}. : ${this.$helpers.toFileSize(data)})` : ``
        }
      },
      computedHint() {
        const hint = !!this.hint ? `${this.$helpers.ucfirst(this.hint)}<br />` : ``
        const msg = !!this.extensions
          ? `${hint} ${this.$t(`components.files_extensions`)} : ${this.extensions.join(`, `)}`
          : hint
        return {
          data: this.extensions,
          msg
        }
      },
      extensions() {
        const filesExt = this.$config.public.files_extensions
        const imagesExt = this.$config.public.images_extensions
        return this.requirements?.extensions || [...filesExt, ...imagesExt].sort()
      },
      traductedErrors() {
        return this.$helpers.wrapInArray(this.errorMessages).map((e) => this.$t(e))
      }
    },
    watch: {
      value: {
        deep: true,
        handler(newValue) {
          this.$forceUpdate()
          this.innerValue = this.initValue(newValue)
        }
      },
      '$attrs.requirements': {
        deep: true,
        handler(newValue) {
          this.requirements = newValue
        }
      }
    },
    mounted() {
      this.filteredProps = { ...this.$attrs }
      this.filteredProps.multiple = this.multiple
      if (this.filteredProps.readonly) {
        delete this.filteredProps.readonly
        this.filteredProps.disable = true
      }
      delete this.filteredProps.value
    },
    methods: {
      initValue(value) {
        if (!!value) {
          if (!value.new) {
            value.new = []
          }
          if (!value.saved) {
            value.saved = []
          }
        } else {
          value = { new: [], saved: [] }
        }
        return JSON.parse(JSON.stringify(value))
      },
      openInputMenu() {
        this.$refs.filesField.$refs.input.click()
      },
      refreshComponent(doc = null, emitBlur = true, emitInput = true) {
        console.log(`refreshComponent`, doc, emitInput)
        if (!!doc) {
          this.innerValue = this.initValue(doc)
          if (emitBlur) {
            this.$emit(`blur`, doc)
          }
        }
        if (emitInput) {
          this.$emit(`input`, this.innerValue)
        }
        this.refreshCounter += 1
      },
      getAllValues() {
        const dataNew = this.innerValue.new.map((f, i) => ({
          sourceIndex: i,
          data: f.data || f.content,
          downloadLink: false,
          deleteLink: false,
          fileName: f.name,
          size: f.size,
          deletable: f.deletable
        }))
        // Add index to delete element easily
        const allValues = dataNew.concat(
          this.innerValue.saved.map((f, i) => ({
            sourceIndex: i,
            ...f
          }))
        )
        return allValues.length > 0 ? allValues : undefined
      },
      async deleteDocument(doc) {
        if (!!doc.deleteLink) {
          const deleteLinkWithoutApi = doc.deleteLink.replace(`/frontoffice`, ``)
          const docModule = !!this.token ? this.$api.external : this.$api.documents
          await docModule
            .delete(deleteLinkWithoutApi)
            .then(() => {
              this.innerValue.saved.splice(doc.sourceIndex, 1)
              this.$flashMessage.success({
                title: `${this.$t(`messages.success`)}`,
                message: this.$t(`messages.document_delete`)
              })
            })
            .catch(() => {
              this.$flashMessage.error({
                title: `<${this.$options.name}> : ${this.$t(`messages.error`)}`,
                message: this.$t(`messages.cant_delete_document`)
              })
            })
            .finally(() => this.refreshComponent())
        } else {
          this.innerValue.new.splice(doc.sourceIndex, 1)
          this.refreshComponent()
        }
      },
      isExtensionValid(extensionType) {
        return this.extensions.some((ext) => extensionType.includes(ext))
      },
      inputFiles(newFiles) {
        const arrayFiles = this.$helpers.wrapInArray(newFiles)
        // When file-input change, extract all files and copy content in innerValue
        if (arrayFiles.length > 0) {
          Promise.all(arrayFiles.map((f) => this.getFile(f)))
            .then((values) => {
              if (values.length > 0) {
                if (this.multiple) {
                  values.map(this.serializeFile)
                } else {
                  if (!!this.innerValue.saved && this.innerValue.saved.length > 0) {
                    this.innerValue.saved.forEach((d) => this.deleteDocument(d))
                  }
                  // IMPROVE Remove guard against too bigger files (can rollback from git)
                  this.innerValue.new = []
                  values.map(this.serializeFile)
                }
              }
              this.$emit(`input`, this.innerValue)
              this.$emit(`change`, this.innerValue)
              this.$emit(`blur`, values)
              this.files = []
              this.refreshCounter += 1
            })
            .catch((e) => {
              if (e.message === `Extension`) {
                console.log(e.cause)
                this.errorMessagesExtensionNotValid(e.cause)
              }
            })
        }
      },
      // Read file in a Promise
      getFile(f) {
        this.errors = []
        return new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.onloadend = (e) => {
            if (this.isExtensionValid(f.type)) {
              resolve({
                name: f.name,
                size: f.size,
                content_type: f.type || null,
                content: e.currentTarget.result,
                deletable: true
              })
            } else {
              reject(new Error(`Extension`, { cause: f }))
            }
          }
          reader.readAsDataURL(f)
        })
      },
      dropFiles(e) {
        this.dragging = false
        this.inputFiles(Object.values(e.dataTransfer.files).filter((value) => !!value.name && !!value.size))
      },
      errorMessagesfileTooBig(file) {
        this.$flashMessage.error({
          title: this.$t(`messages.error`),
          message: `${file.name}: ${this.$t(`messages.file_too_big`)} (max. ${this.$helpers.toFileSize(
            this.sizeLimit.data
          )})`
        })
      },
      errorMessagesExtensionNotValid(file) {
        this.$flashMessage.error({
          title: this.$t(`messages.error`),
          message: this.$t(`messages.cant_save_document_extension_not_valid`, {
            fileName: file.name,
            extensions: this.extensions.join(`, `)
          })
        })
      },
      serializeFile(file) {
        file.size <= this.sizeLimit.data
          ? (this.innerValue.new = [...this.innerValue.new, file])
          : this.errorMessagesfileTooBig(file)
      }
    }
  }
